.extraIngredient{
    border: 1px solid black;
    display: flex;
    height: 3rem;
    align-items: center;
    padding: 0 1rem;
    margin-bottom: 1.5rem;
    border-radius: 20rem;
}

/* The container */
.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  right: 0.8rem;
  height: 2rem;
  width: 2rem;
  background-color: #eee;
  border-radius: 3rem;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: #df2020;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 0.7rem;
  top: 5px;
  width: 0.5rem;
  height: 1rem;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
