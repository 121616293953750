@import url(https://fonts.googleapis.com/css2?family=RocknRoll+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Istok+Web&display=swap);
.header {
  width: 100%;
  height: 100px;
  line-height: 100px;
  top: 0;
}

@-webkit-keyframes animation {
  from {
    top: -100px;
  }
  to {
    top: 0px;
  }
}

@keyframes animation {
  from {
    top: -100px;
  }
  to {
    top: 0px;
  }
}

.header__shrink {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 99999;
  box-shadow: 5px 5px 15px -5px #d7d6d6;
  -webkit-animation-name: animation;
          animation-name: animation;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}

.logo {
  text-align: center;
}

.logo:hover {
  cursor: pointer;
}

.logo img {
  width: calc(100% - 60%);
  object-fit: contain;
  margin-top: -40px;
}

.logo h5 {
  font-weight: 600;
  font-size: 1rem;
  color: #212245;
  margin-top: -40px;
  margin-bottom: 0;
}

.menu a {
  font-weight: 600;
  font-size: 1rem;
  color: #212245;
  transition: 0.3s;
}

.menu a:hover {
  color: #df2020;
}

.active__menu {
  color: #df2020 !important;
}

.cart__icon {
  position: relative;
}

.cart__badge {
  position: absolute;
  top: 30px;
  right: -10px;
  background: #df2020;
  color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile__menu {
  font-weight: 600;
  display: none;
}

@media only screen and (max-width: 992px) {
  .logo img {
    width: calc(100% - 70%);
  }
  .logo h5 {
    font-size: 0.8rem;
    margin-top: -15px;
  }

  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.425);
    z-index: 99;
    display: none;
  }

  .menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    line-height: 20px;
  }

  .menu a {
    font-size: 0.9rem;
  }

  .mobile__menu {
    display: block;
  }

  .show__menu {
    display: block;
  }
}

.header__closeButton {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 60px;
  padding: 10px 20px;
  position: absolute;
  top: 0.5rem;
  right: 0rem;
  margin: 0.5rem 0;
  display: none;
}

.header__closeButton span i {
  font-size: 1.1rem;
  background: #212245;
  padding: 5px;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .close__button {
    display: flex;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    width: 100%;
    height: 80px;
    line-height: 80px;
  }

  .logo img {
    margin-top: -70px !important;
  }
  .logo h5 {
    margin-top: -55px;
  }
}

@media only screen and (max-width: 480px) {
  .menu {
    width: 100%;
  }
}

.close__button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 60px;
  padding: 10px 20px;
  position: absolute;
  top: 0.5rem;
  right: 0rem;
}

.close__button span i {
  font-size: 1.1rem;
  background: #212245;
  padding: 5px;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
}

.footer {
  padding: 50px 0px;
  background: #fde4e4;
  display: flex;
  text-align: start;
  justify-content: center;
  grid-gap: 8rem;
  gap: 8rem;
  align-items: center;
}

.footer__logo img {
  width: calc(100% - 70%);
  margin-bottom: 1.5rem;
}

.footer__logo h5 {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 15px;
}

.footer__logo p {
  font-size: 0.9rem;
  color: #333;
  line-height: 29px;
}

.delivery__time-item {
  display: flex;
  margin: 0;
  grid-gap: 0.7rem;
  gap: 0.7rem;
  background: transparent !important;
}

.delivery__time-item span {
  font-weight: 600;
  color: #212245;
  margin: 0;
}

.delivery__time-item p {
  color: #212245;
}

.footer__title {
  font-size: 1.1rem;
  color: #212245;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
    text-align: center;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .footer__logo img {
    width: calc(100% - 85%);
  }

  .footer__title {
    font-size: 0.9rem;
  }

  .delivery__time-item span {
    font-size: 0.8rem;
  }

  .delivery__time-item p {
    font-size: 0.7rem;
    margin-bottom: 0;
  }
}

.hero__content {
  padding-top: 70px;
}

.hero__title {
  font-size: 2.5rem;
  line-height: 55px;
}

.hero__title span {
  color: #df2020;
}

.hero__content p {
  font-size: 1rem;
  line-height: 30px;
  color: #777;
}

.order__btn {
  border: none;
  outline: none;
  padding: 7px 20px;
  border-radius: 5px;
  cursor: pointer;
  background: #df2020;
  color: #fff;
  font-weight: 600;
}

.order__btn:hover {
  background: #212245;
}

.order__btn:hover a {
  color: inherit;
  text-decoration: none;
}

.hero__img {
  margin: 40px;
}

@media only screen and (max-width: 768px) {
  .hero__content h5 {
    font-size: 1rem;
  }

  .hero__content p {
    font-size: 0.8rem;
    line-height: 25px;
  }

  .hero__title {
    font-size: 1.5rem;
    line-height: 40px;
  }
}

@media only screen and (max-width: 576px) {
  .hero__content {
    text-align: center;
  }

  .order__btn {
    display: block;
    margin: 0 auto;
  }
}

.product__item {
  border: 1px solid #fde4e4;
  text-align: center;
  padding: 30px;
  cursor: pointer;
  height: 100%;
  min-height: 19rem;
}

.product__img {
  margin-bottom: 20px;
  transition: 0.4s;
}

.product__img:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.product__content h5 a {
  color: #212245;
  font-style: 1rem;
}

.product__content h5 {
  margin-bottom: 30px;
}

.product__price {
  font-weight: 600;
  font-size: 1.2rem;
  color: #df2020;
}

.addTOCART__btn {
  border: none;
  padding: 7px 25px;
  background: #df2020;
  color: #fff;
  border-radius: 5px;
  font-size: 0.9rem;
}

@media only screen and (max-width: 992px) {
  .product__content h5 a {
    font-size: 0.8rem;
  }
  .product__item {
    padding: 20px;
  }
  .addTOCart__btn {
    padding: 5px 15px;
    font-size: 0.7rem;
  }
}

.paginationBttns {
  width: 80%;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
          column-gap: 0.5rem;
  list-style: none;
  margin-top: 30px;
}

.paginationBttns a {
  padding: 5px 13px;
  background: #212245;
  color: #fff !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 5px;
}

.paginationBttns a:hover {
  background: #df2020;
}

.common__section {
  background: linear-gradient(rgba(33, 34, 69, .6980392156862745), rgba(33, 34, 69, .6980392156862745)), url(/static/media/banner-02.1d3252d3.jpg);

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 80px 0px;
}
.extraIngredient{
    border: 1px solid black;
    display: flex;
    height: 3rem;
    align-items: center;
    padding: 0 1rem;
    margin-bottom: 1.5rem;
    border-radius: 20rem;
}

/* The container */
.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  right: 0.8rem;
  height: 2rem;
  width: 2rem;
  background-color: #eee;
  border-radius: 3rem;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: #df2020;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 0.7rem;
  top: 5px;
  width: 0.5rem;
  height: 1rem;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.single__product-content {
  padding-left: 50px;
}

.product__price {
  display: flex;
  align-items: center;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  margin-bottom: 0;
}
.product__price span {
  font-size: 2rem;
}

.extraIngredientsGrid {
    display: grid;
    grid-column-gap: 3rem;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    margin-top: 3rem;
}

.category {
  font-weight: 600;
  color: #212245;
  display: flex;
  align-items: center;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  margin-top: 10px;
}

.category span {
  padding: 5px 10px;
  background: #fde4e4;
  color: #212245;
  border-radius: 5px;
}

.description__content {
  padding: 30px 0px;
}

.description__content p {
  line-height: 30px;
  color: #212245b6;
}

.description {
  color: #df2020;
}

.img__item {
  cursor: pointer;
}
.related__Product-title {
  font-size: 1.4rem;
}

@media only screen and (max-width: 830px) {
  .product__title {
    font-size: 1.2rem;
  }
  .product__price,
  .product__price span {
    font-size: 1rem;
  }
  .category,
  .category span {
    font-size: 0.8rem !important;
  }

  .description__content p {
    font-size: 0.8rem;
  }

  .related__Product-title {
    font-size: 1.2rem;
  }

  .extraIngredientsGrid {
    grid-template-columns: repeat(2, 1fr)
  }
}

@-webkit-keyframes animation {
  from {
    top: 0;
  }
  to {
    top: 2rem;
  }
}

@keyframes animation {
  from {
    top: 0;
  }
  to {
    top: 2rem;
  }
}

.updateCartNotifiation {
  width: -webkit-fill-available;
  top: 2rem;
  height: 3rem;
  font-size: larger;
  font-weight: bold;
  background-color: #4BB543;
  border-radius: 10px;
  position: fixed;
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 9999;
  -webkit-animation: 1s animation;
          animation: 1s animation;
}

@media only screen and (max-width: 576px) {
  .product__images {
    display: flex;
    flex-direction: row;
  }

  .product__main-img {
    margin-bottom: 20px;
  }
  .addTOCART__btn {
    margin-top: 10px;
  }

  .category.mb-5 {
    display: inline-block;
    margin-right: 10px;
  }

  .extraIngredientsGrid {
    grid-template-columns: repeat(1, 1fr)
  }
}

th {
  text-align: center;
}

.cart__img-box {
  width: 80px;
}

.cart__img-box img {
  width: 100%;
}

.cart__item-del i {
  cursor: pointer;
}

.cart__page-btn {
  display: flex;
  flex-wrap: nowrap;
  margin: 2rem 0;
}

.cart__page-btn button a:hover {
  color: #fff;
}

.cart__subtotal {
  color: #df2020;
  font-size: 1.5rem;
}

.addTOCart__btn {
  border: none;
  padding: 7px 25px;
  background: #df2020;
  color: #fff;
  border-radius: 5px;
  font-size: 0.9rem;
}

@media only screen and (max-width: 768px) {
  th,
  td {
    font-size: 0.8rem;
  }
}

.checkoutMessage {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 1rem auto;
}

.checkoutIcon {
  width: 2rem;
  color: #4bb543;
  height: 2rem;
}

.checkoutTitleContainer {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 0.7rem;
}

@media only screen and (max-width: 480px) {
  .checkoutMessage {
    margin: 1rem 1rem;
  }
}

.cart__item-info img {
  width: 35px;
  height: 35px;
  object-fit: cover;
}

.cart__item:hover {
  background-color: rgba(230, 230, 230, 0.994);
  cursor: pointer;
}

.cart__product-title {
  font-size: 0.8rem;
  font-weight: 600;
}

.cart__product-price {
  font-size: 0.8rem;
}

.cart__product-price span {
  font-size: 0.9rem;
  font-weight: 600;
  color: #df2020;
}

.increase__decrease-btn {
  background: #fde4e4;
  padding: 2px 5px;
  border-radius: 5px;
}

.increase__btn,
.decrease__btn {
  cursor: pointer;
}

.delete__btn {
  font-size: 1.1rem;
  font-weight: 600;
}

.cart__item {
  margin-bottom: 15px;
  border-bottom: 1px solid #fde4e4;
}

@media only screen and (max-width: 576px) {
  .delete__btn {
    font-size: 1rem;
  }
}

.cart__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.639);
  z-index: 99999;
}

.cart {
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  background: #fff;
  z-index: 999999;
}

.cart__item-list {
  height: calc(100vh - 140px);
  overflow-y: auto;
}

.cart__item-list > h6 {
  margin-top: 5rem;
}

.cart__bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px 20px;
  width: 100%;
  height: 80px;
  background: #df2020;
}

.cart__bottom h6 {
  font-size: 1rem;
  color: #fff;
}

.cart__bottom h6 span {
  font-size: 1.3rem;
  font-weight: 600;
}

.cart__bottom button {
  border: none;
  padding: 4px 20px;
  border-radius: 5px;
  background: #fff;
  color: #212245;
}

.cart__bottom button a {
  color: #212245;
  font-weight: 600;
}

@media only screen and (min-width: 870px) {
  .cart {
    width: 25%;
  }
}

.cart__closeButton {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 60px;
  padding: 10px 20px;
  position: absolute;
  top: 0.5rem;
  right: 0rem;
  margin: 0.5rem 0;
  display: none;
}

.cart__closeButton span i {
  font-size: 1.1rem;
  background: #212245;
  padding: 5px;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
}

/* google fonts */

/* reset default style */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* base style */

body {
  background: #fcfcfc;
  font-family: "Istok Web", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "RocknRoll One", sans-serif !important;
}

a {
  text-decoration: none;
  color: unset;
}

h1,
h2 {
  font-size: 2.1rem;
  font-weight: 600;
  color: #212245;
}

section {
  padding: 60px 0px;
}

